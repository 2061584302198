import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import Img from "gatsby-image";
import Layout from '../../components/layout';
import Intro from '../../components/intro';
import ProjectQuote from '../../components/project-quote';
import ProjectMeta from '../../components/project-meta';
import Logo from '../../assets/logoSAWU.svg';
import LogoAzure from '../../assets/microsoft-azure.svg';
import LogoSQL from '../../assets/microsoft-sql-server.svg';
import LogoWebAPI from '../../assets/microsoft-dot-net.png';
import LogoAngular from '../../assets/angular.svg';
import LogoWordpress from '../../assets/wordpress.svg';
import ProjectWrapper from '../../components/project-wrapper';
import ProjectNav from '../../components/project-nav';
import NavArrow from '../../assets/navArrow.svg';

const Sawu = () => {
  const {screenRegistration, mobileHome, screenDashboard} = useStaticQuery(graphql`
    query {
      screenRegistration: file(relativePath: { eq: "sawu/desktopRegistration.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileHome: file(relativePath: { eq: "sawu/mobileHomepage.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenDashboard: file(relativePath: { eq: "sawu/desktopDashboard.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout page="Our Work">
      <Intro>
        <h2>Expanding the Local Buying Program to South America via a multilingual application</h2>
        <p>
          With the overwhelming success of the <strong>Local Buying Program</strong> in Australia,
          BHP and Sawu wanted to replicate the platform in Spanish for their operations in Chile.
        </p>
      </Intro>

      <ProjectWrapper>
        <div className="project">
        <h3>The Project</h3>
          <p>
            Leveraging the success of the Australian platform, we worked closely with BHP, Sawu and C-Res
            to customise, translate and localise the existing system for a Spanish audience. The project
            itself was quite involved and required the replacement of every single English word with a
            placeholder to make the system truly multilingual.
          </p>
          <p>
            The uptake of the system has been outstanding and it has exceeded $2M in approved spend
            in less than two months of operation.
          </p>
        </div>

        <img src={Logo} alt="SAWU logo" className="logo" />

        <div className="solution">
          <h3>The Solution</h3>
          <p>We worked with Sawu to implement a Spanish system incorporating:</p>
          <ul>
            <li>Language Translation</li>
            <li>Supplier Registration and Management</li>
            <li>Buyer Registration and Management</li>
            <li>Work Instruction Management</li>
            <li>Claim Management</li>
            <li>Automated Business Processes</li>
            <li>Reporting and Compliance</li>
            <li>Systems integration with SAP Ariba</li>
          </ul>

          <p>We also manage the day to day operations of the platform including:</p>
          <ul>
            <li>Cloud Hosting</li>
            <li>System Backups and Monitoring</li>
            <li>Performance Tuning and Optimisation</li>
            <li>Feature Enhancements</li>
            <li>Support and Maintenance</li>
            <li>Security Analysis and Review</li>
          </ul>
        </div>

        <div className="screens">
          <Img fluid={mobileHome.sharp.fluid} fadeIn alt="SAWU Home page" />
          <Img fluid={screenDashboard.sharp.fluid} fadeIn alt="SAWU Dashboard page" />
        </div>

        <ProjectMeta>
          <div className="requirements">
            <h3>The Development</h3>
            <ul>
              <li>Translation and Localisation</li>
              <li>Web Application Development</li>
              <li>WordPress Development</li>
              <li>Systems Integration</li>
              <li>UX Design</li>
              <li>Responsive Design</li>
              <li>Progressive Web Application</li>
            </ul>
          </div>
        </ProjectMeta>

        <Img fluid={screenRegistration.sharp.fluid} fadeIn alt="" className="mainImage" />

        <ProjectMeta>
          <h3 className="techHeading">The Technology</h3>
          <div className="techLogos">
            <img src={LogoAzure} alt="Microsoft Azure Cloud logo" />
            <img src={LogoSQL} alt="Microsoft SQL logo" />
            <img src={LogoWebAPI} alt="Microsoft WebAPI logo" />
            <img src={LogoAngular} alt="Angular logo" />
            <img src={LogoWordpress} alt="Wordpress logo" />
          </div>
        </ProjectMeta>

        <ProjectQuote>
          A pesar de las restricciones geográficas y el desafío diferentes culturas y lenguajes que demandó la
          ejecución de este proyecto, el trabajo desarrollado por Talen fue muy profesional, cumpliendo las
          expectativas en tiempo y calidad en la entrega.
          <span className="translation">Despite geographical restrictions, as well as the language and cultural challenges involved in the
          execution of this project, the work developed by Talen was very professional, meeting our expectations
          in terms of time and quality delivery.</span>
        </ProjectQuote>
        <ProjectQuote>
          Como equipo SAWU estamos muy contentos con el trabajo realizado con Talen.  El constante apoyo y respaldo
          durante la implementación del proyecto ha sido destacable, incluso por la diferencia de horario y la
          distancia entre nosotros.
          <span className="translation">As SAWU team, we are very happy with the work done with Talen.  Continuous
          support during the implementation of the project has been remarkable, even for the difference in time and
          distance between us.</span>
        </ProjectQuote>
        <ProjectQuote>
          La plataforma desarrollada es muy intuitiva y fácil de usar tanto para nosotros como para nuestros
          proveedores y clientes. Talen tiene la capacidad técnica para integrar varios sistemas y facilitar nuestro
          trabajo con su desarrollo.
          <span className="translation">The web site developed for us is very intuitive and easy to use by our team,
          our customers and our suppliers. Talen has the technical skills to integrate several systems and make our
          work easier.</span>
        </ProjectQuote>
      </ProjectWrapper>

      <ProjectNav>
        <div className="prev">
          <img src={NavArrow} />
          <Link to="/work/adapt">Adapt</Link>
        </div>
        <div className="next">
          <Link to="/work/south32">South32</Link>
          <img src={NavArrow} />
        </div>
      </ProjectNav>
    </Layout>
  );
};

export default Sawu;
